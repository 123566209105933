import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ListData from "./billing/ListData";
import './App.css';
import Menu from "./components/Menu";
import MyForm from "./billing/MyForm";
import UserDetails from "./billing/UserDetails";
import SignIn from "./components/Login";
import { AuthProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute'; // Make sure to import ProtectedRoute

function App() {
  return (
    <>
      <ToastContainer/>
      <AuthProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/" element={
              <ProtectedRoute>
                <Menu />
                <ListData />
              </ProtectedRoute>
            } />
            <Route path="user/create" element={
              <ProtectedRoute>
                <Menu />
                <MyForm />
              </ProtectedRoute>
            } />
            <Route path="user/:id/edit" element={
              <ProtectedRoute>
                <Menu />
                <UserDetails/>
              </ProtectedRoute>
            } />
            <Route path="Login" element={<SignIn/>} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;