import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';
import {Button} from '@mui/material';
import { Checkbox, FormControl, InputLabel, Select, MenuItem, FormControlLabel} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';

const MyForm = () => {
  const [inputData, setInputData] = useState("");
  const [number, setNumber] = useState("");
  const [did, setDid] = useState("");
  const [domain, setDomain] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [inputs, setInputs] = useState({
    client: '',
    routeip: '',
    extension: '',
    routing: 'default',
});
  const handleDidInput = (event) => {
    setInputData(event.target.value);
    // formatInputData();
  };



  
    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setInputs((prev) => {
            return {...prev, [name]: value}
        })
    };
    const formatInputData = () => {
        const inputLines = inputData.split('\n');
        const number_match = inputLines[0].match(/\((\d{3})\) (\d{3}-\d{4})/);
        const did_match = inputLines[2].match(/[A-Za-z0-9]+/);
        const domain_match = inputLines[3].match(/[A-Za-z0-9.-]+/);
        const password_match = inputLines[4].match(/[A-Za-z0-9@]+/);    
    
        setNumber(number_match[1] + number_match[2].replace('-', ''));
        setDid(did_match[0]);
        setDomain(domain_match[0]);
        setPassword(password_match[0]);
      };
      const sendData =()=>{
        const vonage = [{
            number: `1${number}`,
            did: did,
            domain: domain,
            password: password,
            client: inputs.client,
            routeip: inputs.routeip,
            extension: inputs.extension,
            routing: inputs.routing,
            recording: isChecked
      }];
      const detail = JSON.stringify(vonage);
        axios.post('https://billing1.geekdialer.com/magnusAPI/vonage.php/vonage/save', detail).then((response)=>{
          toast.success(response.data, { position: toast.POSITION.TOP_RIGHT });
        })
      }
    

  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <Grid container spacing={2} justifyContent="center" style={{marginTop: '20px'}}>
        {isMobile? (null) :(<Grid item sm={0} md={3} ></Grid>)}
      <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label="Input Data"
          multiline
          rows={6.5}
          variant="outlined"
          fullWidth
          value={inputData}
          onChange={handleDidInput}
        />
        <Button onClick={formatInputData} variant='contained'>Fetch Data</Button>
      </Grid>
      <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label="Number"
          variant="outlined"
          fullWidth
          value={number}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Did"
          variant="outlined"
          fullWidth
          value={did}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Domain"
          variant="outlined"
          fullWidth
          value={domain}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          value={password}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      {isMobile? (null) :(<Grid item sm={0} md={3} ></Grid>)}
          {/*  */}
          {/*  */}
          {isMobile? (null) :(<Grid item sm={0} md={3} ></Grid>)}

      <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label="Client Name"
          variant="outlined"
          fullWidth
          name='client'
          onChange={handleInputChange}
          value={inputs.client}
          InputProps={{
            readOnly: false,
          }}
        />
        <TextField
          label="ServerIP"
          disabled = {inputs.routing === "serverip" ? false : true}
          variant="outlined"
          fullWidth
          name='routeip'
          onChange={handleInputChange}
          value={inputs.routeip}
          InputProps={{
            readOnly: false,
          }}
        />
        <FormControlLabel control={<Checkbox defaultChecked value={isChecked} onChange={() =>{setIsChecked(!isChecked)}} />} label="Enable Recording" />
        {/*  */}
        </Grid>
      <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label="Extension"
          variant="outlined"
          fullWidth
          name='extension'
          onChange={handleInputChange}
          value={inputs.extension}
          InputProps={{
            readOnly: false,
          }}
        />
              <FormControl fullWidth>
  <InputLabel id="demo-simple-select-label">Routing</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={inputs.routing}
    label="Route"
    name='routing'
    onChange={handleInputChange}
  >
    <MenuItem value={"default"}>Default</MenuItem>
    <MenuItem value={"name"}>Name</MenuItem>
    <MenuItem value={"serverip"}>ServerIP</MenuItem>
  </Select>
  <Button variant='contained' onClick={sendData}>Send Data</Button>
</FormControl>
      </Grid>
      {isMobile? (null) :(<Grid item sm={0} md={3} ></Grid>)}
    </Grid>
    

    
    
  );
};

export default MyForm;