import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = localStorage.getItem('userName');
    if (user) {
      setCurrentUser({ name: user });
    }
  }, []);

  const login = (userName) => {
    localStorage.setItem('userName', userName);
    setCurrentUser({ name: userName });
  };

  const logout = () => {
    localStorage.removeItem('userName');
    setCurrentUser(null);
  };

  const value = { currentUser, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
