import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Avatar, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const defaultTheme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ userName: '', password: '' });

  const checkUser = (user, password) => {
    const mbillUrl = 'billing1.geekdialer.com';
    axios.get(`https://${mbillUrl}/magnusAPI/index3.php/userchek/${password}/${user}`)
      .then(response => {
        if (response.data) {
          localStorage.setItem("userName", user);
          localStorage.setItem("expiry", Date.now() + 30 * 60 * 1000);
          toast.success("Login Successful");
          navigate("/");
        } else {
          toast.error("Login Failed");
          localStorage.removeItem('userName');
        }
      })
      .catch(error => {
        console.error("Login error:", error);
        toast.error("Login Failed: Network or server error.");
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    checkUser(formData.userName, formData.password);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">Sign in</Typography>
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
            <TextField margin="normal" required fullWidth id="userName" label="User Name" name="userName" autoComplete="userName" autoFocus onChange={handleChange} value={formData.userName} />
            <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="password" onChange={handleChange} value={formData.password} />
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Sign In</Button>
            <Grid container>
              <Grid item xs><Link href="#" variant="body2">Forgot password?</Link></Grid>
              <Grid item><Link href="#" variant="body2">{"Don't have an account? Sign Up"}</Link></Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
